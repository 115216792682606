<template lang="pug">
    section#gestao
        .content
            .text
                Editor(:contents="editor")
            .image
                img(:src="image")
</template>

<script>
import { props } from '@/mixins/component'
import Editor from '@sections/Pagina/Editor/Editor'

export default {
    name: "section-gestao-carreiras",
    props,
    components: {
        Editor,
    },
    computed: {
        editor() {
            return {content: this.contents.content.editor}
        },
        image() {
            return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${this.contents.content.image}`
        },
    },
}
</script>

<style lang="stylus" scoped src="./CareerManagementSection.styl"></style>